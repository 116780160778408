import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

// Components
import Button from './Button'

const Cta = (props) => {

    return (
        <>
            <section className="bg-template-greenDark my-2">
                <div className="py-8 xs:px-4 sm:px-4 md:px-10 container mx-auto">
                    <div className="flex flex-col place-items-start m-auto sm:flex-row sm:place-items-center">
                        <div className="flex-grow">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-thin text-white pb-2">{props.title}</h2>
                            <div className="cmscontent text-white pb-6">
                                <ReactMarkdown linkTarget="_blank">{props.text}</ReactMarkdown>
                            </div>
                            <Link to={props.link} >
                                <Button radius={"0px"} btnStyle={"light2"} fontWeight={400}>{props.btntxt}</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cta