// Node Modules
import React from 'react'
import { graphql, Link } from 'gatsby'

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'
import Button from '../components/Button'
import Cta from '../components/Cta'
import ProjectGallery from '../components/ProjectGallery'

const ProjectList = ({ data, location, pageContext }) => {
  const projects = data.projectdata
  const settings = data.projectsettings.childMarkdownRemark.frontmatter
  const { currentPage, numProjPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numProjPages
  const prevPage = currentPage - 1 === 1 ? "/projects/" : ("/projects/" + (currentPage - 1).toString() + "/")
  const nextPage = ("/projects/" + (currentPage + 1).toString() + "/")
  const settingprojfeatimageLocal = data.projectsettings.childMarkdownRemark.settingprojfeatimageLocal
  return (
    <>
      <Layout location={location}>
        <Seo strTitle={settings.settingprojseotitle} strDescription={settings.settingprojseometadesc} strImage={settingprojfeatimageLocal.publicURL} />
        <section className="projects bg-white container mx-auto pt-4 mt-20">
          <ProjectGallery projects={projects} />
          {numProjPages > 1 && (
            <div className="container mx-auto">
              <div className="flex flex-row justify-between pt-3 pb-2">
                <div className="text-left">
                  {!isFirst && (
                    <Link to={prevPage} rel="prev">
                      <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>Previous Page</Button>
                    </Link>
                  )}
                </div>
                <div className="text-left">
                  {!isLast && (
                    <Link to={nextPage} rel="prev">
                      <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>Next Page</Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
        <Cta link={settings.settingprojctalnk} btntxt={settings.settingprojctabtn} title={settings.settingprojctatitle} text={settings.settingprojctatext} />
      </Layout>
    </>
  )
}

export default ProjectList

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    projectsettings: file(relativePath: {eq: "settings/projects.md"}) {
        id
        childMarkdownRemark {
            settingprojfeatimageLocal {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED,
                        formats: [AUTO, WEBP]
                        transformOptions: {fit: COVER, cropFocus: CENTER}
                    )
                }
                ext
                publicURL
            }
            frontmatter {
                settingprojfeatimagealt
                settingprojtitle
                settingprojseotitle
                settingprojseometadesc
                settingprojctatitle
                settingprojctatext
                settingprojctabtn
                settingprojctalnk
            }
        }
    }
    projectdata: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(projects)/.*[.]md$/"}}, 
        sort: {fields: [frontmatter___projnumber], order: ASC}, 
        limit: $limit, 
        skip: $skip
      ) {
      edges {
        node {
          fields {
            slug
          }
          id
          featuredimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED,
                formats: [AUTO, WEBP]
                transformOptions: {fit: COVER, cropFocus: CENTER}
              )
            }
            ext
            publicURL
          }
          excerpt(pruneLength: 250)
          frontmatter {
            production
            datepub(formatString: "DD MMMM, YYYY")
            title
            projnumber
            featuredimage
            featuredimagealt
          }
        }
      }
    }
  }
`