import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

const ProjectGallery = ({ projects }) => {

    return (
        <>
            <div className="overflow-hidden grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-8 md:gap-12 lg:grid-cols-3 lg:gap-24 xl:gap-28 xs:px-4 sm:px-4 md:px-10 sm:pt-4 pb-4 sm:pb-8">
                {projects.edges.filter(edge => edge.node.frontmatter.production === true).map((edge, i) => {
                    const frontmatter = edge.node.frontmatter;
                    const image = getImage(edge.node.featuredimageLocal);
                    // const titleText = frontmatter.title.replace(/(^[0-9]+:+)|(^[0-9]+\.+)|(^[0-9]+)/g, '');
                    const titleText = frontmatter.title;
                    // let titleNo = i += 1;
                    let titleNo = frontmatter.projnumber;
                    if (titleNo < 10) { titleNo = "0" + titleNo };
                    return (
                        <div className="" key={i} >
                            <Link to={`/projects${edge.node.fields.slug}/`}>
                                <div className="overflow-hidden">
                                    <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                                        className="w-full transform hover:scale-105 duration-500 ease-in-out"
                                    />
                                </div>
                                <div>
                                    <span className="text-2xl md:text-3xl text-template-greenDark">{titleNo}</span>
                                </div>
                                <div className="text-base md:text-lg text-template-greenDark">
                                    <h2>{titleText}</h2>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </>
    );
};

export default ProjectGallery;
